import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import RequestEmail from '../components/RequestEmail/RequestEmail'

export default function BlogThreeReasonsPage() {
  return (
    <>
      <Header />
      <div className="max-w-2xl px-6 py-16 mx-auto space-y-12">
        <article className="space-y-8 dark:bg-gray-800 dark:text-gray-50">
          <div className="space-y-6">
            <h1 className="text-4xl font-bold md:tracking-tight md:text-5xl">3 Reasons Why You Shouldn’t Use Google Maps For Real Estate Listing Maps</h1>
            <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center dark:text-gray-400">
              <div className="flex items-center md:space-x-2">
                <img src="https://source.unsplash.com/75x75/?portrait" alt="" className="w-4 h-4 border rounded-full dark:bg-gray-500 dark:border-gray-700" />
                <p className="text-sm">Louis Wilbrink • March 1st, 2023</p>
              </div>
              <p className="flex-shrink-0 mt-3 text-sm md:mt-0">4 min read</p>
            </div>
          </div>
          <div className="dark:text-gray-100">
            <p>When it comes to selling real estate, the popular mantra comes to mind: “Location, location, location”.  Buyers want to know: are grocery stores, cafes and restaurants nearby?  What about parks and elementary schools?  But what can real estate agents do to highlight a property’s local amenities?</p>
          </div>
          <div className="dark:text-gray-100">
            <p>It’s understandable that some agents would think of the map app they use most: Google Maps!  But before you take a screenshot and add it to your listing, be aware of some potential pitfalls of using Google Maps for your real estate listing map.</p>
          </div>
          <h3 className="text-3xl font-bold dark:text-white">You have no control over what pins and venues populate on the map</h3>
          <div className="dark:text-gray-100">
            <p>Ever notice that when you zoom in and out of a map, you see certain pins appear or disappear?  This is caused by “primacy rules” that govern what a map shows or doesn’t show at a given location & zoom level.  It’s necessary because any map of meaningful size carries such dense information that it’s impossible to show everything at once.  So it does its best to surface information that might be useful for the consumer.</p>
          </div>
          <div className="dark:text-gray-100">
            <p>Unfortunately, these rules give the real estate agent no control over what will ultimately appear on the map.  If a tattoo parlor or industrial workshop appears due to those “primacy rules”, you’re stuck with it.  If the organic grocery store around the corner doesn’t appear on the map, it’s a real missed opportunity to highlight the listing’s location.</p>
          </div>
          <div className="dark:text-gray-100">
            <p>Better Listing Map actually takes the opposite approach in that we believe less is more.  Our maps have the flexibility to re-program those “primacy rules” and surface exactly what will sell the property’s location benefits the most: grocery stores, coffee shops, schools, parks, and restaurants.  We also suppress a lot of “noise” in the map; things that won’t matter one way or another to the buyer, but only distract them from the true gems of the neighborhood.</p>
          </div>
          <h3 className="text-3xl font-bold dark:text-white">Lack of recognizable brand logos</h3>
          <div className="dark:text-gray-100">
            <p>Google Maps (and also Apple Maps) display generic pins and icons across their maps.  This makes sense since they need to build a consistent viewing experience spanning the entire globe.</p>
          </div>
          <div className="dark:text-gray-100">
            <p>However, failing to use a business’ brand logos is a missed opportunity.  Unlike plain text, brand logos are immediately recognizable and help the viewer process what amenities are nearby quickly.  Home buyers often look at hundreds of listings, so displaying a map that is super easy to scan gives them the information they need to inform their decision to view or bid on a house.
</p>
          </div>
          <div className="dark:text-gray-100">
            <p>Branded logos, baked into the map if possible, offer the best of both worlds: the logos are familiar & recognizable and the map tiles offer a sense of direction and distance to the viewer.  Better Listing Map also includes one or two route ribbons & travel times to further hint at the distance of each business.</p>
          </div>
          <h3 className="text-3xl font-bold dark:text-white">Using Google Maps for commercial use runs against their Terms of Service agreement</h3>
          <div className="dark:text-gray-100">
            <p>Beware when casually taking a screenshot of a Google Map and pasting it into your online listing or brochure.  Google Maps’ Terms of Service agreement highlights that it is a consumer application meant for personal and non-commercial use only.  Any commercial use requires a request to Google to use their content, and failing to obtain approval carries non-zero risk and liability.  And obtaining approval might be a long shot as their terms explicitly say to avoid “...creative use in printed promotional or advertising materials”.</p>
          </div>
          <div className="dark:text-gray-100">
            <p>The safest approach is to use online tools or map-making services that carry no such liability.  Services such as <a href="https://betterlistingmap.com" class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">Better Listing Map</a> provide maps in multiple image formats and dimensions specifically to include in marketing material such as online listings and open house brochures.</p>
          </div>
        </article>
        <div>
          <div className="flex flex-wrap py-6 space-x-2 border-t border-dashed dark:border-gray-400">
            <a className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#AmenityMaps</a>
            <a className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#ListingMap</a>
            <a href="#" className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#PropertyListings</a>
          </div>
        </div>
      </div>
      <RequestEmail />
      <Footer />
    </>
  )
}

const features = [
  {
    name: 'Customize your map',
    description: 'Show buyers where they\'ll shop, eat, and hang out.  Selling a starter home to a family?  Your map can highlight the nearby playgrounds, schools, and ice cream shoppes.',
  },
  { 
    name: 'Add to online listings', 
    description: 'Buyers don\'t like reading a wall of text.  Instead, add your map to the photos section.' },
  {
    name: 'Make your listing presentations stand out',
    description: 'Want to stand out from the competition?  Show home sellers that you\'ve done your homework and earn new business.',
  },
  { 
    name: 'Provide accurate travel times', 
    description: 'There\'s no realtor guessing game here.  We pull our travel times directly from the same apps on your phone.' 
  }
]

export default function TieredImages() {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
          <div>
            <div className="border-b border-gray-200 pb-10">
              <h2 className="font-medium text-gray-500">Show don't tell</h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                A picture is worth a thousand words 
              </p>
            </div>

            <dl className="mt-10 space-y-10">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div>
            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
              <img
                src="maps/536-regent-pl-routed-hi-res.webp"
                alt="map with routing"
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4 sm:gap-6 sm:mt-6 lg:gap-8 lg:mt-8">
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src="features/walking-route.webp"
                  alt="walking route feature"
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src="features/Metro-10-min.webp"
                  alt="subtext feature"
                  className="w-full h-full object-center object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import Header from '../components/Header';
import Faqs from '../components/Faqs'
import Footer from '../components/Footer/Footer'

export default function ThankYouPage() {
  return (
  <>
    <Header />
    <div className="bg-white">
      <main className="overflow-hidden">
        {/* Header */}
        <div className="bg-warm-gray-50">
          <div className="py-24 lg:py-32">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                Thank you!
              </h1>
              <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                We'll be in touch shortly.  Until then, return <a href="/" className="text-indigo-600 hover:text-indigo-500">home</a> or check out our FAQs below.
             </p>
            </div>
          </div>
        </div>
      </main>
    </div>
    <Faqs />
    <Footer />
  </>
  )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './index.css';

import App from './App';
import LandingPage from './pages/LandingPage'
import ContactUsPage from './pages/ContactUsPage'
import GetAMapPage from './pages/GetAMapPage'
import BlogPage from './pages/BlogPage'
import BlogThreeReasonsPage from './pages/BlogThreeReasonsPage'
import GalleryPage from './pages/GalleryPage'
import ThankYouPage from './pages/ThankYouPage'
import SignUpPage from './pages/SignUpPage'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<LandingPage />} />
          <Route path="gallery" element={<GalleryPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/three-reasons-why-you-shouldnt-use-google-maps-for-real-estate-listing-maps" element={<BlogThreeReasonsPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="get-a-map" element={<GetAMapPage />} />
          <Route path="thank-you" element={<ThankYouPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

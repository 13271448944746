import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import RequestEmail from '../components/RequestEmail/RequestEmail'
import DecorativeDotPatternRight from '../components/DecorativeDotPatternRight'
import DecorativeDotPatternLeft from '../components/DecorativeDotPatternLeft'
import GalleryHeading from '../components/GalleryHeading'

export default function GalleryPage() {
  return (
    <>
      <Header />
      <div className="bg-white">
        <main className="overflow-hidden">
          <GalleryHeading />
          <section className="relative bg-white contact-us-form-container" aria-labelledby="contact-heading">
            <div className="absolute w-full h-1/6 md:h-1/4 bg-warm-gray-50" aria-hidden="true" />
            <DecorativeDotPatternRight />
            <div className="grid grid-cols-10 gap-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative bg-indigo-50 col-start-1 col-end-11 md:col-start-2 md:col-end-10 rounded-md">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span className="block">Start with a blank map</span>
                    <span className="block text-indigo-600">And add only the best locations</span>
                  </h2>
                </div>
              </div>
              <div className="relative z-10 bg-white col-start-1 col-end-11 md:col-start-2 md:col-end-10 shadow-xl rounded-lg">
                <img 
                  className="w-full h-full object-right object-cover"
                  src="maps/rosslyn-square.png"
                  alt="rosslyn map"
                />
              </div>
              <div className="relative z-10 bg-white col-start-1 col-end-11 md:col-start-2 md:col-end-10 shadow-xl rounded-lg">
                <img 
                  className="w-full h-full object-right object-cover"
                  src="maps/Riggs Square.png"
                  alt="Riggs Street listing map"
                />
              </div>
              <div className="relative bg-warm-gray-50 col-start-1 col-end-11 md:col-start-2 md:col-end-8 rounded-md">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-20 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span className="block">Listing a family home?</span>
                    <span className="block text-indigo-600">Highlight schools and parks</span>
                  </h2>
                </div>
              </div>
              <div className="relative z-10 h-[200px] md:h-full bg-indigo-500 col-start-1 col-end-11 md:col-start-8 md:col-end-10 shadow-xl rounded-lg">
                <img 
                  className="w-full h-full object-right object-cover"
                  src="features/school.png"
                  alt="School highlight"
                />
              </div>
              <div className="relative z-10 bg-white col-start-1 col-end-11 md:col-start-2 md:col-end-10 shadow-xl rounded-lg">
                <div className="flex justify-center">
                  <img 
                    className="w-full h-full shadow-lg object-right object-cover"
                    src="maps/536-regent-pl-routed-hi-res.webp"
                    alt="Regent Place listing map"
                  />
                </div>
              </div>
              <div className="relative z-10 bg-indigo-500 col-start-1 col-end-6 md:col-start-2 md:col-end-4 shadow-xl rounded-lg">
                <img 
                  className="w-full h-full object-center object-cover"
                  src="features/walking-route.webp"
                  alt="Walking route"
                />
              </div>
              <div className="relative z-10 bg-indigo-500 col-start-6 col-end-11 md:col-start-4 md:col-end-6 shadow-xl rounded-lg">
                <img 
                  className="w-full h-full object-center object-cover"
                  src="features/Metro-10-min.webp"
                  alt="Transit with travel time"
                />
              </div>
              <div className="relative z-10 bg-indigo-50 col-start-1 col-end-11 md:col-start-6 md:col-end-10 rounded-md">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-20 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span className="block">Accurate travel times</span>
                    <span className="block text-indigo-600">No guessing here</span>
                  </h2>
                </div>
              </div>
              <div className="relative z-10 bg-white col-start-1 col-end-11 md:col-start-2 md:col-end-10 shadow-xl rounded-md">
                <div className="flex justify-center">
                  <img 
                    className="w-full h-full shadow-lg object-right object-cover"
                    src="maps/gary.webp"
                    alt="Somerville listing Map"
                  />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 w-full h-2/5 bg-warm-gray-50" aria-hidden="true" />
            <DecorativeDotPatternLeft />
          </section>
        </main>
        <RequestEmail />
      </div>
      <Footer />
    </>
  )
}

import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import RequestEmail from '../components/RequestEmail/RequestEmail'

export default function BlogPage() {
  return (
    <>
      <Header />
      <div className="max-w-2xl px-6 py-16 mx-auto space-y-12">
        <a href="/blog/three-reasons-why-you-shouldnt-use-google-maps-for-real-estate-listing-maps">
          <article className="space-y-8 dark:bg-gray-800 dark:text-gray-50">
            <div className="space-y-6">
              <h1 className="text-4xl font-bold md:tracking-tight md:text-5xl">3 Reasons Why You Shouldn’t Use Google Maps For Real Estate Listing Maps</h1>
              <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center dark:text-gray-400">
                <div className="flex items-center md:space-x-2">
                  <img src="https://source.unsplash.com/75x75/?portrait" alt="" className="w-4 h-4 border rounded-full dark:bg-gray-500 dark:border-gray-700" />
                  <p className="text-sm">Louis Wilbrink • March 1st, 2023</p>
                </div>
                <p className="flex-shrink-0 mt-3 text-sm md:mt-0">4 min read</p>
              </div>
            </div>
            <div className="dark:text-gray-100">
              <p>When it comes to selling real estate, the popular mantra comes to mind: “Location, location, location”.  Buyers want to know: are grocery stores, cafes and restaurants nearby?  What about...</p>
            </div>
          </article>
        </a>
        <div>
          <div className="flex flex-wrap py-6 space-x-2 border-t border-dashed dark:border-gray-400">
            <a rel="noopener noreferrer" href="#" className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#AmenityMaps</a>
            <a rel="noopener noreferrer" href="#" className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#ListingMaps</a>
            <a rel="noopener noreferrer" href="#" className="px-3 py-1 rounded-sm hover:underline dark:bg-violet-400 dark:text-gray-900">#PropertyListings</a>
          </div>
        </div>
      </div>
      <RequestEmail />
      <Footer />
    </>
  )
}

import { Container } from '../Container'
import { Logo } from '../Logo'

export default function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-10 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <a href="/" className="font-medium text-gray-500 hover:text-gray-900">Home</a>
              <a href="/blog" className="font-medium text-gray-500 hover:text-gray-900">Blog</a>
              <a href="/gallery" className="font-medium text-gray-500 hover:text-gray-900">Gallery</a>
              <a href="/contact-us" className="font-medium text-gray-500 hover:text-gray-900">Contact Us</a>
              <a href="/get-a-map" className="font-medium text-gray-500 hover:text-gray-900">Get A Map</a>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6"></div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Wilforge Labs, LLC. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}

import { Container } from './Container'

const faqs = [
  [
    {
      question: 'How does it work?',
      answer: 'Give us the property address and the best things in the area.  We\'ll create a customize map to put online or in print.',
    },
    {
      question: 'What can be added to the map?',
      answer: 'Anything in the neighborhood!  However, we believe that less is more.  What 5 things would make people want to move into this area?',
    },
    {
      question: 'How fast can a listing map be created?',
      answer: 'To help prepare realtors for those urgent listing presentations, 24-hours rush orders are available.'
    },
  ],
  [
    {
      question: 'How will I receive my map?',
      answer: 'We\'ll email you the high-resolution images in .PNG and .WEBP format (and in different dimensions).  We\'ll also provide a link where we store the map in the cloud.',
    },
    {
      question: 'How is it legal to use other company logos?',
      answer: 'We honor all corporate logo rules determined by their published guidelines.',
    },
    {
      question: 'How do I pay for the map?',
      answer: 'We use Stripe Invoicing.  You\'ll receive an emailed invoice with a link to pay with a credit card.',
    },
  ],
  [
    {
      question: 'If I\'m a brokerage firm, how can I get maps for all of my listings?',
      answer: 'We offer volume pricing, so send us an email at admin@betterlistingmap.com.'
    },
    {
      question: 'Why can\'t I just use Google Maps for this?',
      answer: 'Many consumer mapping apps disallow commercial use of their products.  Our maps carry no such liability.',
    },
    {
      question: 'How do you make your maps so crisp?',
      answer:
        'Our maps leverage WebGL and Scalar Vector Graphics in order to maintain the highest image fidelity.  The last thing you need is to have blurry maps.',
    },
  ],
]

export default function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"
        src='background-faqs.jpg'
        alt=""
        width={1558}
        height={946}
        unoptimized="true"
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, <a href="/contact-us" className="text-indigo-600 hover:text-indigo-500">Contact Us</a> and we will get back to you.
          </p>
        </div>
        <ul
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}

import { Widget } from '@typeform/embed-react'

export default function SignUpPage() {
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get('email');
  const hidden = { email };

  return (
    <>
      <Widget id="yL50vWUy" className="absolute top-0 left-0 right-0 bottom-0" hidden={hidden} />
    </>
  )
}

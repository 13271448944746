import Hero from '../components/Hero/Hero'
import Statement from '../components/Statement/Statement'
import TieredImages from '../components/TieredImages/TieredImages'
import Pricing from '../components/Pricing/Pricing'
import RequestEmail from '../components/RequestEmail/RequestEmail'
import Faqs from '../components/Faqs'
import Footer from '../components/Footer/Footer'

export default function Example() {
  return (
    <>
      <Hero />
      <Statement />
      <TieredImages />
      <Pricing />
      <RequestEmail />
      <Faqs />
      <Footer />
    </>
  )
}

